/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { API } from "@roambee/client-utility";
import * as React from "react";
import { useEffect, useState } from "react";
// @ts-ignore
import { Table, Pagination } from "@roambee/client-styleguide";
import { Skeleton, Typography } from "@mui/material";

type Node = {
	name: string;
	visibility: string;
	issues: string;
	address: string;
	overlaps: string;
	intersects: string;
};

function NodesTable() {
	const columns = [
		{
			field: "name",
			headerName: "Name",
			isSortable: false,
			isSearchable: true
		},
		{
			field: "address",
			headerName: "Address",
			isSortable: false,
			isSearchable: false
		},
		{
			field: "visibility",
			headerName: "Visibility",
			width: 8,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "overlaps",
			headerName: "Overlaps",
			width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "intersects",
			headerName: "Intersections",
			width: 12,
			isSortable: false,
			isSearchable: false
		}
	];
	const [data, setData] = useState<[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalNodes, setTotalNodes] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [currentData, setCurrentData] = useState<Node[]>([]);
	const [originalData, setOriginalData] = useState<Record<string, string | number>[][]>([]);
	const [isSearching, setIsSearching] = useState<boolean>(false);

	const fetchData = async () => {
		try {
			const nodesData = await API("GET", `/account/node-intel/`);
			setOriginalData(nodesData);
			resetData(nodesData);
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setLoading(false);
		}
	};

	const resetData = (nodesData) => {
		const modifiedData = nodesData?.data?.map((pageData) => {
			return pageData?.data.map((node) => {
				const over = (
					<>
						<u>Zones:</u>
						<br />
						{Object.values(node["overlaps"]?.zone).join(", ") || "None!"}
						<br />
						<u>Locations:</u>
						<br />
						{Object.values(node["overlaps"]?.location).join(", ") || "None!"}
					</>
				);
				const inter = (
					<>
						<u>Zones:</u>
						<br />
						{Object.values(node["intersections"]?.zone).join(", ") || "None!"}
						<br />
						<u>Locations:</u>
						<br />
						{Object.values(node["intersections"]?.location).join(", ") || "None!"}
					</>
				);
				return { ...node, overlaps: over, intersects: inter };
			});
		});
		setData(modifiedData);
		setTotalPages(nodesData?.totalPages);
		setIsSearching(false);
		setCurrentData(Object.values(modifiedData?.[currentPage - 1] || {}));
		setTotalNodes(nodesData?.totalNodes);
	};

	const updateData = () => {
		setCurrentData(Object.values(data[currentPage - 1] || {}));
		setLoading(false);
	};
	const handleSearch = (searchObj: Record<string, unknown>) => {
		const searchString = searchObj?.search?.[0]?.value || "";
		if (!searchString) {
			resetData(originalData);
			return;
		}
		const filteredData = data.flatMap((pageData: Node[]) => pageData?.filter((node) => node["name"].toLowerCase().includes(searchString.toLowerCase())));
		setCurrentData(filteredData);
		setTotalPages(1);
		setCurrentPage(1);
		setIsSearching(true);
	};

	useEffect(() => {
		setLoading(true);
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSearching) return;
		setLoading(true);
		if (currentData.length) updateData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	return (
		<section id="node-table-container">
			<Typography variant="h6" id="node-info">
				Total Nodes: {totalNodes} | Total Groups: {originalData?.["totalPages"] || 0}
			</Typography>
			{!loading && (
				<div className="group-table">
					<Table showFilters={false} isSelectable={false} columns={columns} visibleColumns={columns} data={currentData} total={totalNodes} page={currentPage} pageSize={currentData?.length} pagination={false} sticky={true} className={"node-table"} handleEvent={handleSearch} />
					<Pagination className="node-table-pagination" total={totalPages} current={currentPage} onChangePage={(value: number) => setCurrentPage(value)} />
				</div>
			)}
			{loading && <Skeleton className="skeleton"></Skeleton>}
		</section>
	);
}

export default NodesTable;
