import { createElement } from "react";
import { Home, DeliveryParcel, Delivery, Location, WifiController } from "@carbon/icons-react";

const routes = [
	{
		path: "/hc20",
		title: ""
	},
	{
		path: "hc20/dashboard",
		title: "Dashboard",
		icon: createElement(Home),
		visible: true
	},
	{
		path: "hc20/shipments",
		title: "Shipments",
		icon: createElement(Delivery),
		visible: true
	},
	{
		path: "hc20/bees",
		title: "Bees",
		icon: createElement(WifiController),
		visible: true
	},
	{
		path: "hc20/assets",
		title: "Assets",
		icon: createElement(DeliveryParcel),
		visible: true
	},
	{
		path: "hc20/ni",
		title: "Node Intelligence",
		icon: createElement(Location),
		visible: true
	}
];
export default routes;
