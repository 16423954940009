/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import NodesTable from "./components/NodesTable";
// @ts-ignore
import { EventEmitter, API, useAuthUser, setRoutes } from "@roambee/client-utility";
// @ts-ignore
import { GlobalSearch } from "@roambee/client-styleguide";
import { useNavigate } from "react-router-dom";
import "./App.scss";
import { ENDPOINTS } from "../src/NodeIntelConfig";
import routes from "./Routes";

function App() {
	const navigate = useNavigate();
	const { data: user, authenticated, loading } = useAuthUser();
	const [isWide, setWide] = useState(true);
	const [accounts, setAccounts] = useState([]);
	const [account, setAccount] = useState({});
	const [userDetails, setUserDetails] = useState<Record<string, unknown>>({});

	setRoutes(routes);

	const menuItems = [
		{
			lable: "Logout",
			clickHandler: () => {
				API("POST", ENDPOINTS.LOGOUT, {}).then(() => {
					navigate(ENDPOINTS.AUTH);
				});
			}
		}
	];
	useEffect(() => {
		EventEmitter.addListener("toggleSideNav", (eventData) => {
			setWide(eventData);
		});

		return () => {
			EventEmitter.removeListener("toggleSideNav", (eventData) => {
				setWide(eventData);
			});
		};
	}, []);

	useEffect(() => {
		if (!loading && authenticated && user) {
			setUserDetails(user);
			API("GET", ENDPOINTS.ACCOUNTS_QUICKVIEW)
				.then((result) => {
					if (result.rows && result.rows.length) {
						setAccounts(result.rows);
					}
					if (user && user.account && user.account.data) {
						setAccount({ label: user.account.data?.title, id: user.account?.uuid });
					}
				})
				.catch((error) => {
					if (error.status === 404 && error.message) {
						// eslint-disable-next-line no-console
						console.error(error.message);
					} else {
						// eslint-disable-next-line no-console
						console.error("Error:", error);
						EventEmitter.emit("showSnackbar", {
							id: "error",
							leftIcon: true,
							message: error?.message || "Something went wrong!",
							variant: "error",
							ariaLabel: "close button",
							position: "top-right"
						});
					}
				});
		}
	}, [authenticated, loading, user]);

	const handleSwitchAccount = async (event, newValue) => {
		if (!newValue || newValue === "") {
			// to handle event when user erases previous account and is typing next one
			return;
		}
		const newCustomerDetails = {
			email: "",
			account_uuid: ""
		};

		accounts.forEach((account) => {
			if (account.uuid === newValue.id) {
				newCustomerDetails.email = account.users[0].email;
				newCustomerDetails.account_uuid = account.uuid;
				return;
			}
		});
		await API("POST", ENDPOINTS.SWITCH_ACCOUNTS, newCustomerDetails).then(() => {
			window.location.reload();
		});
	};

	return (
		<section id="main-layout" className={isWide ? "sidenav-normal" : "sidenav-small"}>
			<section id="header">
				<GlobalSearch
					className="header"
					user={user}
					showSearch={false}
					showNotification={false}
					title={"Node Intelligence"}
					menuItems={menuItems}
					accounts={accounts.map((account) => {
						return { label: account.title, id: account.uuid };
					})}
					account={account}
					handleSwitchAccount={handleSwitchAccount}
				/>
			</section>
			<section id="container-layout">{userDetails["current_account_id"] && <NodesTable isSuperUser={userDetails?.["current_account_id"] === 1} />}</section>
		</section>
	);
}

export default App;
